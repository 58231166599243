import Vue from "vue";
import Vuex from "vuex";
import server from "../api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    consignment_invoices: {
      invoices: [],
      original_items: [],
      remaining_items: [],
    },
    overlay: false,
    customerOrderItem: [],
    customerName: "",
    orders: [],
    totalOrders: 0,
    select_products: [],
    select_customers: [],
    order: {},
    products: [],
    samples: [],
    sample: {},
    testers: [],
    tester: {},
    provinces: [],
    cities: [],
    successMessage: "",
    errorMessage: "",
    isLogin: "",
    couriers: [],
    courierList: [],
    courier: {},
    customers: [],
    customer: {},
    totalCustomers: 0,
    categories: [],
    promotions: [],
    promotion: {},
    internalTransfers: [],
    vendors: [],
    select_vendors: [],
    totalSamples: 0,
    totalTesters: 0,
    vendor: {},
    totalVendors: 0,
    warehouses: [],
    warehouse: {},
    returns: [],
    product: {},
    select_orders: [],
    customer_types: [
      {
        text: "Individual",
        value: "individual",
      },
      {
        text: "Retailer",
        value: "retailer",
      },
      {
        text: "Online Retailer",
        value: "online_retailer",
      },
      {
        text: "Reseller",
        value: "reseller",
      },
      {
        text: "Wholesaler",
        value: "wholesaler",
      },
      {
        text: "Stockist",
        value: "stockist",
      },
    ],
    purposes: [
      {
        text: "Review",
        value: "review",
      },
      {
        text: "Replacement",
        value: "replacement",
      },
      {
        text: "Training",
        value: "training",
      },
      {
        text: "Development",
        value: "development",
      },
      {
        text: "Giveaway",
        value: "giveaway",
      },
      {
        text: "Photoshoot",
        value: "photoshoot",
      },
      {
        text: "Media",
        value: "media",
      },
      {
        text: "Marketing",
        value: "marketing",
      },
      {
        text: "Sponsorship",
        value: "sponsorship",
      },
      {
        text: "Tester",
        value: "tester",
      },
    ],
    shipment_statuses: [
     {value: "Processed", label: "Processed"},
     {value: "Label Printed", label: "Label Printed"},
     {value: "Shipped", label: "Shipped"},
     {value: "Completed", label: "Completed"},
     {value: "Canceled", label: "Gosend Cancelled"},
      // gojek
     {value: "Booked", label: "Gosend Booked"},
     {value: "Confirmed", label: "Gosend Confirmed"},
     {value: "Allocated", label: "Gosend Allocated"},
     {value: "Out_for_delivery", label: "Gosend Delivery"},
     {value: "Delivered", label: "Gosend Delivered"},
     {value: "Out_for_pickup", label: "Gosend Pickup"},
     {value: "Picked", label: "Gosend Picked"},
     {value: "On_hold", label: "Gosend Hold"},
     {value: "Rejected", label: "Gosend Rejected"},
    ],
    tester_statuses: [
      "Pending",
      "Accepted",
      "Rejected",
    ],
    sales_types: [
      {
        text: "Tokopedia",
        value: "tokopedia",
      },
      {
        text: "Shopee",
        value: "shopee",
      },
      {
        text: "BukaLapak",
        value: "bukalapak",
      },
      {
        text: "Blibli",
        value: "blibli",
      },
      {
        text: "JDid",
        value: "jdid",
      },
      {
        text: "Lazada",
        value: "lazada",
      },
      {
        text: "WhatsApp",
        value: "whatsapp",
      },
      {
        text: "Instagram",
        value: "instagram",
      },
      {
        text: "TikTok",
        value: "tiktok",
      },
      {
        text: "Orami",
        value: "orami",
      },
      {
        text: "Amazon",
        value: "amazon",
      },
      {
        text: "Crystal Web",
        value: "crystal",
      },
    ],
    order_statuses: [
      {
        text: "Payment Pending",
        value: "payment_pending",
      },
      {
        text: "Payment Success",
        value: "payment_success",
      },
      {
        text: "Canceled",
        value: "canceled",
      },
      {
        text: "Payment Expired",
        value: "payment_expired",
      },
    ],
    ageRange: [
      {
        text: "Normal Adult",
        value: "normal_adult",
      },
      {
        text: "1 - 3 Years",
        value: "1-3years",
      },
      {
        text: "7 - 11 Months",
        value: "7-11mths",
      }
    ],
    stockCount: [],
    tableLoading: false,
    retailPartner: [],
    detailRetail: [],
    faq: [],
    detailFaq: {},
    faqCategory: [],
    detailFaqCategory: {},
    recipe: [],
    ingredient: [],
    detailRecipe: {},
    recipeCategory: [],
    cookingType: [],
    newsletterSubscriber: [],
  },
  mutations: {
    SET_CONSIGNMENT_INVOICES(state, payload) {
      state.consignment_invoices = {
        ...payload,
      };
    },
    SET_ORDERS(state, payload) {
      state.orders = payload;
    },
    SET_LOGIN(state, payload) {
      state.isLogin = payload;
    },
    SET_ERROR(state, payload) {
      state.errorMessage = payload;
      setTimeout(() => {
        state.errorMessage = "";
      }, 3000);
    },
    SET_SUCCESS(state, payload) {
      state.successMessage = payload;
      setTimeout(() => {
        state.successMessage = "";
      }, 3000);
    },
    SET_PRODUCTS(state, payload) {
      state.products = payload;
    },
    SET_SAMPLES(state, payload) {
      state.samples = payload;
    },
    SET_TESTERS(state, payload) {
      state.testers = payload;
    },
    SET_PROVINCES(state, payload) {
      state.provinces = payload;
    },
    SET_CITIES(state, payload) {
      state.cities = payload;
    },
    SET_COURIERS(state, payload) {
      state.couriers = payload;
    },
    SET_COURIERS_LIST(state, payload) {
      state.courierList = payload;
    },
    SET_CUSTOMERS(state, payload) {
      state.customers = payload;
    },
    SET_PROMOTIONS(state, payload) {
      state.promotions = payload;
    },
    SET_INTERNAL_TRANSFER(state, payload) {
      state.internalTransfers = payload;
    },
    SET_VENDORS(state, payload) {
      state.vendors = payload;
    },
    SET_VENDOR(state, payload) {
      state.vendor = payload;
    },
    SET_WAREHOUSES(state, payload) {
      state.warehouses = payload;
    },
    SET_RETURNS(state, payload) {
      state.returns = payload;
    },
    SET_PRODUCT(state, payload) {
      state.product = payload;
    },
    SET_COURIER(state, payload) {
      state.courier = payload;
    },
    SET_WAREHOUSE(state, payload) {
      state.warehouse = payload;
    },
    SET_CUSTOMER(state, payload) {
      state.customer = payload;
    },
    SET_TOTAL_CUSTOMER(state, payload) {
      state.totalCustomer = payload;
    },
    SET_PROMOTION(state, payload) {
      state.promotion = payload;
    },
    SET_ORDER(state, payload) {
      state.order = payload;
    },
    SET_SAMPLE(state, payload) {
      state.sample = payload;
    },
    SET_TESTER(state, payload) {
      state.tester = payload;
    },
    SET_OVERLAY(state, payload) {
      state.overlay = payload;
    },
    SET_CATEGORIES(state, payload) {
      state.categories = payload;
    },
    SET_TOTAL_ORDER(state, payload) {
      state.totalOrders = payload;
    },
    SET_SELECT_PRODUCTS(state, payload) {
      state.select_products = payload;
    },
    SET_SELECT_CUSTOMERS(state, payload) {
      state.select_customers = payload;
    },
    SET_TOTAL_CUSTOMERS(state, payload) {
      state.totalCustomers = payload;
    },
    SET_TOTAL_VENDORS(state, payload) {
      state.totalVendors = payload;
    },
    SET_SELECT_VENDORS(state, payload) {
      state.select_vendors = payload;
    },
    SET_TOTAL_SAMPLES(state, payload) {
      state.totalSamples = payload;
    },
    SET_TOTAL_TESTERS(state, payload) {
      state.totalTesters = payload;
    },
    SET_STOCKCOUNT(state, payload) {
      state.stockCount = payload;
    },
    SET_SELECT_ORDERS(state, payload) {
      state.select_orders = payload;
    },
    SET_TABLE_LOADING(state, payload) {
      state.tableLoading = payload;
    },
    SET_CUSTOMER_NAME(state, payload) {
      state.customerName = payload
    },
    SET_CUSTOMER_ORDER_ITEM(state, payload) {
      state.customerOrderItem = payload
    },
    SET_ALL_DATA_RETAIL(state,  payload) {
      state.retailPartner = payload
    },
    SET_DATA_RETAIL(state,  payload) {
      state.detailRetail = payload
    },
    SET_FAQ(state, payload) {
      state.faq = payload
    },
    SET_FAQ_DETAIL(state,  payload) {
      state.detailFaq = payload
    },
    SET_FAQ_CATEGORY(state,  payload) {
      state.faqCategory = payload
    },
    SET_FAQ_CATEGORY_DETAIL(state,  payload) {
      state.detailFaqCategory = payload
    },
    SET_RECIPE(state, payload) {
      state.recipe = payload
    },
    SET_RECIPE_DETAIL(state, payload) {
      state.detailRecipe = payload
    },
    SET_RECIPE_CATEGORY(state, payload) {
      state.recipeCategory = payload
    },
    SET_INGREDIENT(state, payload) {
      state.ingredient = payload
    },
    SET_COOKING_TYPE(state, payload) {
      state.cookingType = payload
    },
    SET_NEWSLETTER_SUBSCRIBER(state, payload) {
      state.newsletterSubscriber = payload
    },
  },
  actions: {
    getCustomerOrder({commit}, order_id = 0) {
      commit("SET_TABLE_LOADING", true);
      return server.get(`/orders/consignment_invoices/${order_id}`)
      .then(res => {
        commit("SET_CUSTOMER_ORDER_ITEM", res.data.remainingItems)
        commit("SET_CUSTOMER_NAME", res.data.customerName)
        commit("SET_TABLE_LOADING", false);
      })
      .catch(err => {
        commit("SET_ERROR", err.response.data);
        commit("SET_TABLE_LOADING", false);
      })
    },
    getConsignmentInvoices({ commit }, order_id = 0) {
      commit("SET_TABLE_LOADING", true);
      return server
        .get(`orders/${order_id}/consignment_invoices`, {
          headers: {
            token: localStorage.token,
          },
        })
        .then(({ data }) => {
          commit("SET_CONSIGNMENT_INVOICES", data);
          commit("SET_TABLE_LOADING", false);
        })
        .catch((error) => {
          commit("SET_ERROR", error.response.data);
          commit("SET_TABLE_LOADING", false);
        });
    },
    getOrders({ commit }, query = {}) {
      let {
        per_page,
        page,
        sales_type,
        customer,
        status,
        start_date,
        end_date,
        ship_from,
        shipment_status,
        order,
        customer_type,
      } = query;
      if (!per_page) per_page = 15;
      if (!page) page = 1;

      let queryBuilder = `per_page=${per_page}&page=${page}`;

      if (sales_type) queryBuilder += `&sales_type=${sales_type}`;
      if (customer) queryBuilder += `&customer=${customer}`;
      if (start_date) queryBuilder += `&start_date=${start_date}`;
      if (end_date) queryBuilder += `&end_date=${end_date}`;
      if (status) queryBuilder += `&status=${status}`;
      if (ship_from) queryBuilder += `&ship_from=${ship_from}`;
      if (shipment_status)
        queryBuilder += `&shipment_status=${shipment_status}`;
      if (order) queryBuilder += `&order=${order}`;
      if (customer_type) queryBuilder += `&customer_type=${customer_type}`;

      commit("SET_TABLE_LOADING", true);

      return server
        .get(`/orders?${queryBuilder}`, {
          headers: {
            token: localStorage.token,
          },
        })
        .then(({ data }) => {
          commit("SET_ORDERS", data.Orders.rows);
          commit("SET_TOTAL_ORDER", data.Orders.count);
          commit("SET_TABLE_LOADING", false);
        });
    },
    getProducts({ commit }) {
      return server
        .get("/products", {
          headers: {
            token: localStorage.token,
          },
        })
        .then(({ data }) => {
          commit("SET_PRODUCTS", data.Products);
        });
    },
    getSamples({ commit }, query = {}) {
      let {
        per_page,
        page,
        start_date,
        end_date,
        ship_status,
        purpose,
        ship_from,
        sample,
      } = query;
      let queryBuilder = `per_page=${per_page}&page=${page}`;
      if (start_date) queryBuilder += `&start_date=${start_date}`;
      if (end_date) queryBuilder += `&end_date=${end_date}`;
      if (ship_status) queryBuilder += `&ship_status=${ship_status}`;
      if (purpose) queryBuilder += `&purpose=${purpose}`;
      if (ship_from) queryBuilder += `&ship_from=${ship_from}`;
      if (sample) queryBuilder += `&sample=${sample}`;

      return server
        .get(`/samples?${queryBuilder}`, {
          headers: {
            token: localStorage.token,
          },
        })
        .then(({ data }) => {
          commit("SET_TOTAL_SAMPLES", data.Samples.count);
          commit("SET_SAMPLES", data.Samples.rows);
        });
    },
    getTesters({ commit }, query = {}) {
      let {
        per_page,
        page,
        status
      } = query;
      let queryBuilder = `per_page=${per_page}&page=${page}`;
      if (status) queryBuilder += "&status=" + status;

      return server
        .get(`/tester?${queryBuilder}`, {
          headers: {
            token: localStorage.token,
          },
        })
        .then(({ data }) => {
          commit("SET_TOTAL_TESTERS", data.Tester.count);
          commit("SET_TESTERS", data.Tester.rows);
        });
    },
    getProvinces({ commit }) {
      return server.get("/provinces").then(({ data }) => {
        commit("SET_PROVINCES", data.Provinces);
      });
    },
    getCities({ commit }) {
      return server.get("/cities").then(({ data }) => {
        commit("SET_CITIES", data.Cities);
      });
    },
    getCouriers({ commit }) {
      return server.get("/delivery/method").then(({ data }) => {
        commit("SET_COURIERS", data.Couriers);
      });
    },
    getCourierList({ commit }) {
      return server.get("/delivery/fees").then(({ data }) => {
        commit("SET_COURIERS_LIST", data.Couriers);
      });
    },
    getCustomers({ commit }, query = {}) {
      let { per_page, page, customer, customer_type, cityId } = query;
      if (!per_page) per_page = 15;
      if (!page) page = 1;
      let queryBuilder = `per_page=${per_page}&page=${page}`;
      if (customer) queryBuilder += `&customer=${customer}`;
      if (customer_type) queryBuilder += `&customer_type=${customer_type}`;
      if (cityId) queryBuilder += `&cityId=${cityId}`;
      return server.get(`/customers?${queryBuilder}`).then(({ data }) => {
        commit("SET_CUSTOMERS", data.Customers.rows);
        commit("SET_TOTAL_CUSTOMERS", data.Customers.count);
      });
    },
    getPromotions({ commit }) {
      return server.get("/promotion").then(({ data }) => {
        commit("SET_PROMOTIONS", data.Promotions);
      });
    },
    getInternalTransfer({ commit }) {
      return server.get("/internal_transfer").then(({ data }) => {
        commit("SET_INTERNAL_TRANSFER", data.InternalTransfer);
      });
    },
    getVendors({ commit }, query = {}) {
      let { per_page, page, company_name, vendor } = query;
      if (!per_page) per_page = 15;
      if (!page) page = 1;
      let queryBuilder = `per_page=${per_page}&page=${page}`;
      if (vendor) queryBuilder += `&vendor=${vendor}`;
      if (company_name) queryBuilder += `&company_name=${company_name}`;
      return server.get(`/vendors?${queryBuilder}`).then(({ data }) => {
        commit("SET_VENDORS", data.Vendors.rows);
        commit("SET_TOTAL_VENDORS", data.Vendors.count);
      });
    },
    getWarehouses({ commit }) {
      return server.get("/warehouse").then(({ data }) => {
        commit("SET_WAREHOUSES", data.Warehouses);
      });
    },
    getReturns({ commit }) {
      return server.get("/returns").then(({ data }) => {
        commit("SET_RETURNS", data.Returns);
      });
    },
    getCategories({ commit }) {
      return server.get("/category").then(({ data }) => {
        commit("SET_CATEGORIES", data.ItemCategory);
      });
    },
    getProductById({ commit }, { id }) {
      return server.get(`/products/${id}`).then(({ data }) => {
        let product = data.Product

        let faqEng = data.Product.ItemFaqs.filter(faq => faq.locale === "en_US")[0]
        if(faqEng != null) {
          product.engFaqTitle = faqEng.title
          product.engFaqBody = faqEng.body
        }

        let faqId = data.Product.ItemFaqs.filter(faq => faq.locale === "id_ID")[0]
        if(faqId != null) {
          product.idFaqTitle = faqId.title
          product.idFaqBody = faqId.body
        }

        commit("SET_PRODUCT", product);
      });
    },
    getCourierById({ commit }, { id }) {
      return server.get(`/delivery/fees/${id}`).then(({ data }) => {
        commit("SET_COURIER", data.Delivery);
      });
    },
    getWarehouseById({ commit }, { id }) {
      return server.get(`/warehouse/${id}`).then(({ data }) => {
        commit("SET_WAREHOUSE", data.Warehouse);
      });
    },
    getCustomerById({ commit }, { id }) {
      commit("SET_OVERLAY", true);
      return server.get(`/customers/${id}`).then(({ data }) => {
        commit("SET_OVERLAY", false);
        commit("SET_CUSTOMER", data.Customer);
      });
    },
    getVendorById({ commit }, { id }) {
      return server.get(`/vendors/${id}`).then(({ data }) => {
        commit("SET_VENDOR", data.Vendor);
      });
    },
    getPromotionById({ commit }, { id }) {
      return server.get(`/promotion/${id}`).then(({ data }) => {
        commit("SET_PROMOTION", data.Promotion);
      });
    },
    getOrderById({ commit }, { id }) {
      return server.get(`/orders/${id}`).then(({ data }) => {
        commit("SET_ORDER", data.Order);
      });
    },
    getSampleById({ commit }, { id }) {
      commit("SET_OVERLAY", true);
      return server.get(`samples/${id}`).then(({ data }) => {
        commit("SET_SAMPLE", data.Sample);
        commit("SET_OVERLAY", false);
      });
    },
    getTesterById({ commit }, { id }) {
      commit("SET_OVERLAY", true);
      return server.get(`tester/${id}`).then(({ data }) => {
        commit("SET_TESTER", data.Tester);
        commit("SET_OVERLAY", false);
      });
    },
    getOrdersLite({ commit }) {
      return server.get("/orders/lite").then(({ data }) => {
        commit("SET_SELECT_ORDERS", data.Orders);
      });
    },
    getProductsLite({ commit }) {
      return server.get("/products/lite").then(({ data }) => {
        commit("SET_SELECT_PRODUCTS", data.Products);
      });
    },
    getCustomersLite({ commit }) {
      return server.get("/customers/lite").then(({ data }) => {
        commit("SET_SELECT_CUSTOMERS", data.Customers);
      });
    },
    getVendorsLite({ commit }) {
      return server.get("/vendors/lite").then(({ data }) => {
        commit("SET_SELECT_VENDORS", data.Vendors);
      });
    },
    getStockCount({ commit }, query = {}) {
      let { filter_date, warehouse, item } = query;
      let queryBuilder = "";
      if (filter_date) queryBuilder += `&filter_date=${filter_date}`;
      if (warehouse) queryBuilder += `&warehouse=${warehouse}`;
      if (item) queryBuilder += `&item=${item}`;
      commit("SET_TABLE_LOADING", true);

      return server
        .get(`/inventory/stock/count?${queryBuilder}`)
        .then(({ data }) => {
          commit("SET_STOCKCOUNT", data.result);
          commit("SET_TABLE_LOADING", false);
        });
    },
    getAllRetailLocations({ commit }) {
      commit("SET_TABLE_LOADING", true);
      return server.get("/retailer_locations").then(({data}) => {
        commit("SET_ALL_DATA_RETAIL", data.Retailer)
        commit("SET_TABLE_LOADING", false);
      }).catch(()=> commit("SET_TABLE_LOADING", false))
    },
    getRetailLocation({ commit }, {id}) {
      commit("SET_TABLE_LOADING", true);
      return server.get(`/retailer_locations/${id}`, {
        headers: {
          token: localStorage.token,
        }
        }).then(({data}) => {
          commit("SET_DATA_RETAIL", data.Retailer)
          commit("SET_TABLE_LOADING", false);
        }).catch(()=> commit("SET_TABLE_LOADING", false))
    },
    getFaq({ commit }) {
      commit("SET_TABLE_LOADING", true);
      return server.get("/faqs", {
        headers: {
          token: localStorage.token,
        }
        }).then(({data}) => {
          commit("SET_FAQ", data.rows)
          commit("SET_TABLE_LOADING", false);
        }).catch(()=> commit("SET_TABLE_LOADING", false))
    },
    getFaqById({ commit }, {id}) {
      commit("SET_TABLE_LOADING", true);
      return server.get(`/faqs/${id}`, {
        headers: {
          token: localStorage.token,
        }
        }).then(({data}) => {
          commit("SET_FAQ_DETAIL", data)
          commit("SET_TABLE_LOADING", false);
        }).catch(()=> commit("SET_TABLE_LOADING", false))
    },
    getFaqCategory({ commit }) {
      commit("SET_TABLE_LOADING", true);
      return server.get("/faq_categories", {
        headers: {
          token: localStorage.token,
        }
        }).then(({data}) => {
          commit("SET_FAQ_CATEGORY", data.rows)
          commit("SET_TABLE_LOADING", false);
        }).catch(()=> commit("SET_TABLE_LOADING", false))
    },
    getFaqCategoryById({ commit }, {id}) {
      commit("SET_TABLE_LOADING", true);
      return server.get(`/faq_categories/${id}`, {
        headers: {
          token: localStorage.token,
        }
        }).then(({data}) => {
          commit("SET_FAQ_CATEGORY_DETAIL", data)
          commit("SET_TABLE_LOADING", false);
        }).catch(()=> commit("SET_TABLE_LOADING", false))
    },
    getRecipe({ commit }) {
      commit("SET_TABLE_LOADING", true);
      return server.get("/nutrition/recipe", {
        headers: {
          token: localStorage.token,
        }
        }).then(({data}) => {
          commit("SET_RECIPE", data.data.rows)
          commit("SET_TABLE_LOADING", false);
        }).catch(()=> commit("SET_TABLE_LOADING", false))
    },
    getRecipeDetail({ commit }, {slug}) {
      commit("SET_TABLE_LOADING", true);
      return server.get(`/nutrition/recipe/${slug}`, {
        headers: {
          token: localStorage.token,
        }
        }).then(({data}) => {
          let result = data.data
          result.recipeCategoryName = result.RecipeCategory.name
          result.servingSize = result.serving_size
          result.cookingMethod = result.cooking_method
          result.servingTime = result.serving_time
          result.ageRange = result.age_range
          result.recipeInfo = result.RecipeLines.map(line => {
            let newLine = line
            newLine.id = line.IngredientList.id
            newLine.Ingredient_name = line.IngredientList.Ingredient_name
            newLine.amount = line.quantity

            return newLine
          })

          commit("SET_RECIPE_DETAIL", result)
          commit("SET_TABLE_LOADING", false);
        }).catch(()=> commit("SET_TABLE_LOADING", false))
    },
    getRecipeCategory({ commit }) {
      commit("SET_TABLE_LOADING", true);
      return server.get("/nutrition/recipe-category", {
        headers: {
          token: localStorage.token,
        }
        }).then(({data}) => {
          commit("SET_RECIPE_CATEGORY", data)
          commit("SET_TABLE_LOADING", false);
        }).catch(()=> commit("SET_TABLE_LOADING", false))
    },
    getIngredient({ commit }) {
      commit("SET_TABLE_LOADING", true);
      return server.get("/nutrition", {
        headers: {
          token: localStorage.token,
        }
        }).then(({data}) => {
          commit("SET_INGREDIENT", data.data)
          commit("SET_TABLE_LOADING", false);
        }).catch(()=> commit("SET_TABLE_LOADING", false))
    },
    getCookingType({ commit }) {
      commit("SET_TABLE_LOADING", true);
      return server.get("/nutrition/cooking-type", {
        headers: {
          token: localStorage.token,
        }
        }).then(({data}) => {
          commit("SET_COOKING_TYPE", data.data)
          commit("SET_TABLE_LOADING", false);
        }).catch(()=> commit("SET_TABLE_LOADING", false))
    },
    getNewsletterSubscribers({ commit }) {
      return server.get("/newsletter_subscribers").then(({ data }) => {
        console.log(data)
        commit("SET_NEWSLETTER_SUBSCRIBER", data.NewsletterSubscriber.rows);
      });
    },
  },
  modules: {},
});
