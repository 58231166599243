<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center" justify="space-around">
        <v-col
        cols="6"
        >
            <h2>Orders</h2>
        </v-col>
        <v-col
        cols="6">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search Order Id"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                        v-model="filterOrder"
                        @keydown.enter="searchOrder"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/add/order">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Add New Order
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="2">
            <div class="pa-3 crystal-admin-form">
                <label>Date</label>
                <v-row>
                    <v-col
                    cols="12"
                    >
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="filterDate"
                            transition="scale-transition"
                            offset-y
                            min-width="350px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="filterDateText"
                                label="Choose Date"
                                readonly
                                v-bind="attrs"
                                append-icon="mdi-calendar"
                                clearable
                                hide-details
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="filterDate"
                                no-title
                                scrollable
                                range
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(filterDate)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col cols="2">
            <div class="pa-3 crystal-admin-form">
                <label>Channel</label>
                <v-select
                    label="Choose Channel"
                    :items="sales_types"
                    
                    v-model="filterChannel"
                    clearable
                    multiple
                >
                </v-select>
                <!-- <multi-select 
                    :options="options"
                    :items="sales_types"
                    v-model="filterChannel"
                    @checked="onCheck"
                >
                </multi-select> -->
                <!-- <multiselect v-model="value" :options="options"></multiselect> -->
            </div>     
        </v-col>
        <v-col cols="2">
            <div class="pa-3 crystal-admin-form">
                <label>Customer</label>
                <v-text-field
                    placeholder="Customer"
                    v-model="filterCustomer"
                    @keydown.enter="searchCustomer"
                >
                </v-text-field>
            </div>   
        </v-col>
        <v-col cols="2">
            <div class="pa-3 crystal-admin-form">
                <label>Ship From</label>
                <v-select
                    label="Choose Warehouse"
                    :items="warehouses"
                    item-text="LocationName"
                    item-value="id"
                    v-model="filterWarehouse"
                    clearable
                >
                </v-select>
            </div>
        </v-col>
        <v-col cols="2">
            <div class="pa-3 crystal-admin-form">
                <label>Shipment Status</label>
                <v-select
                    label="Shipment Status"
                    :items="shipment_statuses"
                    item-text="label" 
                    item-value="value"
                    v-model="filterShipStatus"
                    clearable
                >
                </v-select>
            </div>  
        </v-col>
        <v-col cols="2">
            <div class="pa-3 crystal-admin-form">
                <label>Payment Status</label>    
                <v-select
                    label="Payment Status"
                    :items="statusFilters"
                    v-model="filterStatus"
                >
                </v-select>
            </div>
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <Alert />
        <v-col
            cols="12"
        >
            <v-data-table
                :headers="headers"
                :items="orders"
                :server-items-length="totalOrders"
                :options="pagination"
                @update:options="paginate"
                :footer-props="{
                    'items-per-page-options': listSize,
                }"
                @update:items-per-page="getItemPerPage"
                class="elevation-1"
            >
                <template v-slot:[`item.order_date`]="{ item }">
                    <td>{{ formatDate(item.order_date) }}</td>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                        v-if="item.OrderStatus == 'payment_pending'"
                    >
                        Payment Pending
                    </v-chip>
                    <v-chip
                        v-else-if="item.OrderStatus == 'payment_success'"
                        color="green"
                        text-color="white"
                    >
                        Payment Success
                    </v-chip>
                    <v-chip
                        v-else-if="item.OrderStatus == 'canceled'"
                        color="red"
                        text-color="white"
                    >
                        Canceled
                    </v-chip>
                    <v-chip
                        v-else-if="item.OrderStatus == 'payment_expired'"
                        color="red"
                        text-color="gray"
                    >
                        Payment Expired
                    </v-chip>
                </template>
                <template v-slot:[`item.Customer`]="{ item }">
                    {{ item.Customer ? item.Customer.fullName : "-" }}
                </template>
                <template v-slot:[`item.grand_total`]="{ item }">
                    <div v-if="item.OrderLine">
                        {{ formatHarga(item.OrderLine.grand_total) }}
                    </div>
                </template>
                <template v-slot:[`item.sales_type`]="{ item }">
                    {{ item.sales_type ? capitalizeFirstLetter(item.sales_type) : '' }}
                </template>
                <template v-slot:[`item.product`]="{ item }">
                    <div v-if="item.OrderLine">
                        <span v-for="(product, index) in item.OrderLine.items.slice(0, 2)" :key="product.id">
                        {{ product.Item ? product.Item.name : '' }}{{` (x${product.qty})` }}{{ index === 1 && item.OrderLine.items.length - 2 != 0 ? `...${item.OrderLine.items.length - 2} more` : index === item.OrderLine.items.length-1 ? '' : ',' }}
                    </span>
                    </div>
                </template>
                <template v-slot:[`item.ship_from`]="{ item }">
                    {{ item.ShipFromLocation.LocationName }}
                </template>
                <template v-slot:[`item.ship_to`]="{ item }">
                    {{ `${item.ShiptoAddress} ${item.postal_code ? item.postal_code : ""}`}}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small text @click="getDetailOrder(item)">mdi-eye</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <DetailOrder 
        :order="selectedOrder"
        :detail_order="detail_order"
        @updateOrder="updateOrder"
        @closeDetail="closeDetail"
    />
  </v-container>
</template>

<script>
import DetailOrder from '@/components/DetailOrder.vue'
import Alert from '@/components/Alert.vue'
import server from '../api'

export default {
    name: 'Order',
    components: {
        DetailOrder,
        Alert,
    },
    data () {
        var salesTypeList = []
        if (this.$route.query.sales_type) {
            salesTypeList = this.$route.query.sales_type.split(",");
        }
        return {
            menu: false,
            detail_order: false,
            selectedOrder: {},
            headers: [
                { text: 'Date', value: 'order_date'},
                { text: 'Channel', value: 'sales_type'},
                { text: 'Customer', value: 'Customer'},
                { text: 'Product', value: 'product'},
                { text: 'Ship From', value: 'ship_from'},
                { text: 'Ship', value: 'ship_to' },
                { text: 'Total', value: 'grand_total'},
                { text: 'Ship Status', value: 'ShipStatus'},
                { text: 'Status', value: 'status'},
                { text: 'Action', value: 'actions'}
            ],
            statusFilters: [
                {
                    text: 'All',
                    value: 'all'
                },
                {
                    text: 'Payment Pending',
                    value: 'payment_pending'
                },
                {
                    text: 'Payment Success',
                    value: 'payment_success'
                },
                {
                    text: 'Canceled',
                    value: 'canceled'
                },
                {
                    text: "Payment Expired",
                    value: "payment_expired",
                },
            ],
            filterOrder: this.$route.query.order || '',
            filterStatus: this.$route.query.status || 'all',
            filterCustomer: this.$route.query.customer || '',
            listSize: [15, 25, 50, 100, -1],
            pagination: {
                descending: !!this.$route.query.desc,
                itemsPerPage: +this.$route.query.per_page || 15,
                page: +this.$route.query.page || 1,
                totalItems: this.totalOrders,
            },
            //filterChannel: this.$route.query.sales_type || '',
            filterChannel: salesTypeList,
            filterDate: [],
            filterWarehouse: +this.$route.query.ship_from || '',
            filterShipStatus: this.$route.query.shipment_status || '',
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.fetchData(to);
        next();
    },
    watch: {
        filterChannel (val) {
            //console.log(val);
            var filterString = "";
            for (let i = 0; i < val.length; i++) {
                //console.log(val[i].value);
                filterString += val[i] + ","
            }
            filterString = filterString.slice(0,-1);
            //console.log(filterString);
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            let same = true;
            obj.sales_type = filterString;
            for (let key in obj) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            if (same) this.fetchData(); // page has been manually reloaded in the browser
            else {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: obj
                });
            }
            //console.log(obj);
        },
        filterStatus (val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.status = val;
            if (val === 'all') delete obj.status;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        filterDate (val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.start_date = val[0] ? val[0] : '';
            obj.end_date = val[1] ? val[1] : '';
            if (!obj.start_date) delete obj.start_date;
            if (!obj.end_date) delete obj.end_date;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        filterWarehouse (val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.ship_from = val;
            if (!val) delete obj.ship_from;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        filterShipStatus (val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.shipment_status = val;
            if (!val) delete obj.shipment_status;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        filterOrder (val) {
            if (!val) {
                this.searchOrder();
            }
        }
    },
    methods: {
        getItemPerPage(val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.per_page = +val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        searchCustomer () {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.customer = this.filterCustomer;
            if (this.filterCustomer === '') delete obj.customer;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        searchOrder () {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.order = this.filterOrder;
            if (this.filterOrder === '') delete obj.order;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        getDetailOrder(item) {
            this.selectedOrder = item;
            Promise.all(this.selectedOrder.OrderLine.items.map(async (order) => {
                let item = await server.get(`/products/${order.ItemId}`);
                return {
                    ...order,
                    gross_weight: item.data.Product.gross_weight
                }
            })).then((data) => {
                this.selectedOrder.OrderLine.items = data;
                this.detail_order = true
            });
        },
        updateOrder(id) {
            server.put(`/orders/${id}`, {
                ShipFromLocationId: this.selectedOrder.ShipFromLocationId,
                DeliveryMethodId: this.selectedOrder.DeliveryMethodId,
                ShiptoAddress: this.selectedOrder.ShiptoAddress,
                OrderStatus: this.selectedOrder.OrderStatus,
                ShipStatus: this.selectedOrder.ShipStatus,
                tax: this.selectedOrder.tax,
                tracking_code: this.selectedOrder.tracking_code,
                items: this.selectedOrder.OrderLine.items,
                memo: this.selectedOrder.memo,
                shipment_date: this.selectedOrder.shipment_date,
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.$store.commit('SET_SUCCESS', result.data.message);
                    this.detail_order = !this.detail_order;
                })
                .catch(error => {
                    this.$store.commit('SET_ERROR', error.response.data);
                })
        },
        closeDetail () {
            this.detail_order = !this.detail_order;
        },
        fetchData (route) {
            this.$store.dispatch('getOrders', route.query);
        },
        paginate (val) {
            // emitted by the data-table when changing page, rows per page, or the sorted column/direction - will be also immediately emitted after the component was created
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            if (val.itemsPerPage !== this.listSize[0]) obj.per_page = val.itemsPerPage;
            if (val.descending) obj.desc = 'true';
            else delete obj.desc;
            obj.orderby = val.sortBy;
            obj.page = val.page;
            obj.per_page = val.itemsPerPage;
            // check if old and new query are the same - VueRouter will not change the route if they are, so probably this is the first page loading
            let same = true;
            for (let key in query) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            // to handle the case when a KEY exists in OBJ but not in query
            for (let key in obj) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            if (same) this.fetchData(); // page has been manually reloaded in the browser
            else {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: obj
                });
            }
        },
    },
    computed: {
        orders () {
            //console.log(this.$route.query.sales_type);
            return this.$store.state.orders;
        },
        totalOrders () {
            return this.$store.state.totalOrders;
        },
        filterDateText: {
            get () {
                return this.filterDate.join(' - ');
            },
            set () {
                this.filterDate = [];
            }
        },
        warehouses () {
            return this.$store.state.warehouses;
        },
        sales_types () {
            return this.$store.state.sales_types;
        },
        shipment_statuses () {
            return this.$store.state.shipment_statuses;
        },
    },
    created () {
        this.$store.dispatch('getOrders', this.$route.query);
        this.$store.dispatch('getWarehouses');
    }
}
</script>

<style>

</style>