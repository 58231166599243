<template>
    <OrderForm :order="order" @saveOrder="saveOrder" :buttonLoading="buttonLoading" :params="getParams" />
</template>

<script>
import OrderForm from '@/components/OrderForm.vue'
import server from '../api';

export default {
    name: 'AddOrder',
    components: {
        OrderForm
    },
    data() {
        let order = {};
        if (this.$session.get("isDuplicate") === 1) {
            order = {
                city_id: this.$session.get("orderData").city_id,
                postal_code: this.$session.get("orderData").postal_code,
                province_id: this.$session.get("orderData").province_id,
                ShiptoAddress: this.$session.get("orderData").ShiptoAddress,
                ShipStatus: 'Processed',
                OrderStatus: 'payment_pending',
                ShipFromLocationId: '',
                order_date: new Date().toISOString().substr(0, 10),
                shipment_date: new Date().toISOString().substr(0, 10),
                DeliveryMethodId: 1,
                sales_type: this.$session.get("orderData").sales_type,
                customer_type:this.$session.get("orderData").customer_type,
                Customer: {
                    fullName: this.$session.get("orderData").Customer.fullName || '',
                    CustomerContact: {
                        contactNo: this.$session.get("orderData").Customer.CustomerContact.contactNo || ''
                    }
                },
                OrderLine: {
                    items: this.$session.get("orderData").OrderLine.items || []
                },
                tax: 0,
                delivery_fee: 0,
                memo: '',
                invoice_no: ''
            }
        } else {
            order = {
                city_id: '',
                postal_code: '',
                province_id: '',
                ShiptoAddress: '',
                ShipStatus: 'Processed',
                OrderStatus: 'payment_pending',
                ShipFromLocationId: '',
                order_date: new Date().toISOString().substr(0, 10),
                shipment_date: new Date().toISOString().substr(0, 10),
                DeliveryMethodId: 1,
                sales_type: '',
                customer_type: '',
                Customer: {
                    fullName: '',
                    CustomerContact: {
                        contactNo: ''
                    }
                },
                OrderLine: {
                    items: []
                },
                tax: 0,
                delivery_fee: 0,
                memo: '',
                invoice_no: ''
            }
        }
        return {
            order,
            buttonLoading: false,
            getParams: null
        }
    },
    befoureRouteEnter(to, from, next) {
        next(vm => {
            vm.getParams = from;
        });
    },
    mounted: function () {
        this.$session.set("orderData", "");
        this.$session.set("isDuplicate", 0);
    },
    methods: {
        saveOrder(grand_total) {
            this.buttonLoading = !this.buttonLoading;
            let firstName;
            let middleName;
            let lastName;
            if (this.order.Customer.fullName) {
                let name = this.order.Customer.fullName.split(' ');
                firstName = name[0] ? name[0] : '';
                middleName = name[1] ? name[1] : '';
                lastName = name[2] ? name[2] : '';
            }
            server.post('/customers', {
                firstName,
                middleName,
                lastName,
                city_id: this.order.city_id,
                postal_code: this.order.postal_code,
                province_id: this.order.province_id,
                contactNo: `${this.order.Customer.CustomerContact.contactNo}`,
                addressLine1: this.order.ShiptoAddress,
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(results => {
                    return server.post('/orders', {
                        CustomerId: results.data.Customer.id,
                        ShipFromLocationId: this.order.ShipFromLocationId,
                        DeliveryMethodId: this.order.DeliveryMethodId,
                        ShiptoAddress: this.order.ShiptoAddress,
                        OrderStatus: this.order.OrderStatus,
                        ShipStatus: this.order.ShipStatus,
                        items: this.order.OrderLine.items,
                        sales_type: this.order.sales_type,
                        customer_type: this.order.customer_type,
                        city_id: this.order.city_id,
                        postal_code: this.order.postal_code,
                        province_id: this.order.province_id,
                        order_date: this.order.order_date,
                        shipment_date: this.order.shipment_date,
                        tax: this.order.tax,
                        delivery_fee: this.order.delivery_fee,
                        grand_total,
                        memo: this.order.memo,
                        invoice_no: this.order.invoice_no,
                        shipping_method: 'manual'
                    }, {
                        headers: {
                            token: localStorage.token
                        }
                    })
                })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `berhasil menambahkan Order ${result.data.Order.id}`);
                    this.$router.go(-1);
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_ERROR', err.response.data.errors.message);
                });
        },
    },
}
</script>

<style>

</style>